exports.columns = [
  {data: 'id'},
  {data: 'type'},
  {data: 'store'},
  {data: 'status'},
  {data: 'payment_method'},
  {data: 'total'},
  {data: 'created'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
